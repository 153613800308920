import React, { memo, useEffect, useRef, useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { isProd } from "utils/utils";
import useTabs from "hooks/useTabs";
import { useBusinessProfile } from "hooks/useFetch";

type Props = {
	path: string;
	setPath: (path: string) => void;
};
const NavigationTab = ({ path, setPath }: Props) => {
	const { data: businessProfile } = useBusinessProfile();
	const profile = businessProfile?.data;
	const calledOnce = useRef(false);
	const [index, setIndex] = useState(0);
	const { push, query, locale } = useRouter();
	const tabs = useTabs();

	const filteredVisibleTabs = useMemo(() => {
		return tabs.filter((tab) => tab.visible);
	}, [tabs]);

	const pushUrl = useCallback(
		(t: string) => {
			let _locale = `/${locale}/`;
			if (locale === "default") {
				_locale = "/";
			}
			push(
				{
					pathname: isProd() ? `/b${_locale}${profile?.key}` : profile?.key,
					query: { t },
				},
				undefined,
				{ shallow: true, scroll: true }
			);
		},
		[locale, profile?.key, push]
	);

	// Get active tab from QueryString
	useEffect(() => {
		if (calledOnce.current) return;
		if (!query.t) return;
		const tab = tabs.find((menu) => menu.path === query.t);

		if (tab !== undefined && tab.visible) {
			setPath(tab?.path);
			const _index = filteredVisibleTabs.findIndex((x) => x.path === tab?.path);
			setIndex(_index);
		} else {
			pushUrl("about");
		}
		calledOnce.current = true;
	}, [filteredVisibleTabs, pushUrl, query.t, setPath, tabs]);

	const onChange = useCallback(
		(tab: string, i: number) => {
			pushUrl(tab);
			setPath(tab);
			setIndex(i);
		},
		[pushUrl, setPath]
	);

	return (
		<div className={"flex border-b border-zinc-200 scroll-smooth overflow-x-auto scrollbar-hide snap-x relative"}>
			<i
				className="absolute h-[3px] w-[144px] bg-[#0A202B] rounded-md left-0 bottom-0 transition-transform duration-300"
				style={{ transform: `translateX(${144 * index}px)` }}
			/>

			{filteredVisibleTabs.map((menu, i) => (
				<button
					type="button"
					onClick={() => onChange(menu.path, i)}
					key={menu.id}
					data-test-id={`tab-${menu.path}`}
					className={classNames(
						"flex-shrink-0 transition-colors duration-75 uppercase",
						"ring-0 focus:outline-none focus:ring-0",
						"select-none",
						"border-b-2 border-transparent",
						"py-4",
						"font-medium text-[#0A202B] text-sm",
						path === menu.path ? "text-[#0A202B]" : "text-[#848487] hover:text-gray-800"
					)}
				>
					<span className="inline-block w-[144px]">{menu.label}</span>
				</button>
			))}
		</div>
	);
};

export default memo(NavigationTab);
