import { useMemo } from "react";
import { useCanRender, useHowManyItems } from "hooks/useRender";
import useTranslation from "next-translate/useTranslation";
import { useBusinessProfile } from "./useFetch";
import { generateSectionLabel } from "utils/utils";

export const useMakeLabel = (field: any, limit = 13) => {
	const { data: businessProfile } = useBusinessProfile();
	const widgetSetting = businessProfile?.data.widget;
	const { t, lang } = useTranslation("common");
	// const fieldToSection: any = `${field}s`;
	// const itemsCount = useHowManyItems(fieldToSection);
	const fallbackName = `navigation.${field}`;

	const titleFromData = (field: any) => {
		const translations = widgetSetting?.settings.language.translations[lang];

		if (!translations) return null;

		return translations[field]?.trimEnd();
	};

	return generateSectionLabel(titleFromData(field), t(fallbackName), limit);
};

const useTabs = () => {
	const { t } = useTranslation("common");
	const isProviderRenderable = useCanRender("provider");
	const isReviewRenderable = useCanRender("review");

	const hasProvidersItems = useHowManyItems("providers") > 0;
	const hasReviewsItems = useHowManyItems("reviews") > 0;
	const hasServicesItems = useHowManyItems("services") > 0;

	const providerLabel = useMakeLabel("provider");
	const serviceLabel = useMakeLabel("service");
	const reviewLabel = useMakeLabel("review");

	const tabs = useMemo(() => {
		return [
			{ id: 0, label: t(`navigation.about`), path: "about", visible: true },
			{
				id: 1,
				label: providerLabel,
				path: "providers",
				visible: hasProvidersItems && isProviderRenderable,
			},

			{
				id: 2,
				label: serviceLabel,
				path: "services",
				visible: hasServicesItems,
			},
			{
				id: 3,
				label: reviewLabel,
				path: "reviews",
				visible: hasReviewsItems && isReviewRenderable,
			},
		];
	}, [
		hasProvidersItems,
		hasReviewsItems,
		hasServicesItems,
		isProviderRenderable,
		isReviewRenderable,
		providerLabel,
		reviewLabel,
		serviceLabel,
		t,
	]);

	return tabs;
};

export default useTabs;
